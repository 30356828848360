import { doGet, doPost } from 'api/carexGateway';
import { CancelToken } from 'axios';
import moment from 'moment';
import {
  CreateAccountPayload,
  resetPasswordPayload,
  ForgotPasswordPayload,
  SetUserPayload,
  ISignIn,
  SignInPayload,
  SignInResponse,
  UserData,
} from 'store/auth/auth.types';

export enum CREATE_DOCTOR_RESPONSES {
  ok = 'OK',
  dupeError = 'ER_DUP_ENTRY',
  dbError = 'Database error!',
  rightTokenError = 'The correct security token is required.',
}

const AuthService = {
  signIn: async (payload: ISignIn, cancelToken?: CancelToken) => {
    try {
      const data = {
        email: payload.email,
        pass: payload.pass,
        otp: payload.otp ? payload.otp : null,
        isOTPLogin: payload.isOTPLogin ? payload.isOTPLogin : null,
        timezone: moment().toISOString(),
      };

      const res = await doPost<SignInPayload, any>('signIn', {
        data: { data },
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getUserId: async (cancelToken?: CancelToken) => {
    try {
      const res = await doGet<UserData[]>('getUserId', {}, cancelToken);

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  createDoctor: async (
    payload: CreateAccountPayload,
    cancelToken?: CancelToken
  ) => {
    try {
      const data = { data: JSON.stringify(payload) };
      const res = await doPost<any, { data: string }>('createDoctor', {
        data,
        cancelToken,
      });

      if (
        typeof res.data === 'object' &&
        res.data.err &&
        Object.values(CREATE_DOCTOR_RESPONSES).includes(res.data.err)
      ) {
        return Promise.reject(new Error(res.data.err));
      }

      if (typeof res.data === 'string') {
        if (res.data === 'OK') {
          return Promise.resolve(res.data);
        }
        if (res.data.includes('token') && res.data.includes('required')) {
          return Promise.reject(new Error(res.data));
        }
      }
      return Promise.reject(new Error(res.data));
    } catch (err) {
      return Promise.reject(err);
    }
  },
  forgotPassword: async (
    payload: ForgotPasswordPayload,
    cancelToken?: CancelToken
  ) => {
    try {
      const data = { data: JSON.stringify(payload) };
      const res = await doPost<any, { data: string }>('forgotPassword', {
        data,
        cancelToken,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  requestOTP: async (payload: string, cancelToken?: CancelToken) => {
    try {
      const data = { data: payload };

      const res = await doPost('requestOTP', {
        data,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  setUser: async (payload: SetUserPayload, cancelToken?: CancelToken) => {
    try {
      const data = {
        oldUser: payload.oldUser,
        newUserId: payload.newUserId,
      };

      const res = await doPost<any, any>('setUser', {
        data,
        cancelToken,
      });

      if (res.data != '') {
        if (res.data.resp == 'allowed') {
          return Promise.resolve(res.data);
        }
      }
      return Promise.reject('Login Error');
    } catch (err) {
      return Promise.reject(err);
    }
  },
  resetPassword: async (
    payload: resetPasswordPayload,
    cancelToken?: CancelToken
  ) => {
    try {
      const data = { data: JSON.stringify(payload) };
      const res = await doPost<any, { data: any }>('resetPassword', {
        data,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  resendVerification: async (query: string, cancelToken?: CancelToken) => {
    try {
      const res = await doGet<any>(
        'resendVerification',
        { query },
        cancelToken
      );

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default AuthService;
