import careexpandTheme from 'utils/constants/themes/careExpandTheme.constant';
import { CETheme } from 'utils/constants/themes/theme.types';

const mcsTheme: CETheme = {
  colors: {
    ...careexpandTheme.colors,
    primary: {
      base: '#00a160',
      100: '#56bdfa', // $malibu-blue
      200: '#2196F3', // $dodger-blue
      300: '#009efc', // $deep-sky-blue
      400: '#96c93d', // alt green
      500: '#5b8def', // $cornflower-blue
      600: '#3667ac', // $azure-blue
    },
  },
  images: {
    logo: '/assets/images/logos/mcs_medilinea_logo.svg',
  },
  overrides: {},
};

export default mcsTheme;
