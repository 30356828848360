import { combineReducers } from '@reduxjs/toolkit';
import legacyAppReducer from 'store/legacyApp/legacyApp.slice';
import authReducer from './auth/auth.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  legacyApp: legacyAppReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
