import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';

const Layout: React.FC = (props) => {
  const { children } = props;
  return (
    <>
      <Header></Header>
      {children}
      <Footer></Footer>
    </>
  );
};

export default Layout;
