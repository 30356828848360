import { doGet } from 'api/carexGateway';
import { CancelToken } from 'axios';

const UsersService = {
  searchProviders: async (searchBy: string, cancelToken?: CancelToken) => {
    try {
      const res = await doGet<any[]>(
        'searchProviders',
        { query: `search_by=${searchBy.trim()}` },
        cancelToken
      );

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default UsersService;
