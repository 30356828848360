import { CETheme } from './constants/themes/theme.types';
import { clientInfo, HOST_NAMES } from 'utils/constants/hostConfig.constants';
import Themes from 'utils/constants/themes';

const hostname = window.location.hostname as HOST_NAMES;

export const currentClient = clientInfo[hostname] ?? clientInfo.careexpand;
export const currentHostSettings = {
  theme: { ...(Themes[currentClient.styleName] as CETheme) },
  clientInfo: currentClient,
};
export const currentHostName = hostname;

export type CurrentHostSettings = typeof currentHostSettings;

(window as any).theming = [currentHostSettings];
