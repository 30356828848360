import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      color: '#fff',
    },
  },
}));
