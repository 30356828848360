import CreateAccount from 'pages/auth/views/createAccount/CreateAccount';
import ResetPassword from 'pages/auth/views/reset-password/ResetPassword';
import SignIn from 'pages/auth/views/signIn/SignIn';
import ResetPasswordForm from 'pages/auth/views/reset-password-form/ResetPasswordForm';

import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

export const AUTH_ROUTES = {
  root: '/auth',
  signIn: '/sign-in',
  createAccount: '/create-account',
  resetPassword: '/reset-password',
  resetPasswordForm: '/reset-password-form/:token',
};

const AuthRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}${AUTH_ROUTES.signIn}`}></Redirect>
      </Route>
      <Route
        path={`${match.path}${AUTH_ROUTES.signIn}`}
        component={SignIn}
      ></Route>
      <Route
        path={`${match.path}${AUTH_ROUTES.createAccount}`}
        component={CreateAccount}
      ></Route>
      <Route
        path={`${match.path}${AUTH_ROUTES.resetPassword}`}
        component={ResetPassword}
      ></Route>
      <Route
        path={`${match.path}${AUTH_ROUTES.resetPasswordForm}`}
        component={ResetPasswordForm}
      ></Route>
    </Switch>
  );
};

export default AuthRouter;
