import * as Yup from 'yup';
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const newPasswordRegex = /(?=.*[A-Z])/;

const name = Yup.string()
  .min(2, 'First name must have 2 characters')
  .max(20, 'Invalid first name')
  .required('Required');

const lastname = Yup.string()
  .min(2, 'Last name must have 2 characters')
  .max(20, 'Invalid last name')
  .required('Required');

const email = Yup.string().email('Invalid email').required('Required');

const password = Yup.string()
  .min(8, 'Password must have 8 characters')
  .required('Required');

const newPassword = Yup.string()
  .min(8, 'Password must be at least 8 characters')
  .matches(newPasswordRegex, 'Password must have at least one capital letter')
  .required('Required');

const repeatNewPassword = Yup.string()
  .min(8, 'Password must be at least 8 characters')
  .matches(newPasswordRegex, 'Password must have at least one capital letter')
  .oneOf([Yup.ref('password'), null], "Passwords don't match")
  .required('Required');

const phone = Yup.string()
  .matches(rePhoneNumber, 'Invalid Phone Number')
  .required('Required');

const securityToken = Yup.string().required('Required');

export const SignInSchema = Yup.object().shape({
  email,
  password,
});

export const ResetPasswordSchema = Yup.object().shape({
  email,
});

export const CreateAccountSchema = Yup.object().shape({
  name,
  lastname,
  email,
  phone,
  securityToken,
});

export const ResetPasswordFormSchema = Yup.object().shape({
  password: newPassword,
  repeatPassword: repeatNewPassword,
});
