import { Box, Button, Card, Typography } from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isResetPasswordLoadingSelector } from 'store/auth/auth.selectors';
import { resetPasswordThunkAction } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ResetPasswordFormSchema } from 'validations';

interface ResetPasswordForm {
  password: string;
  repeatPassword: string;
}

const MySwal = withReactContent(Swal);

const ResetPassword = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isResetPasswordLoadingSelector);
  const history = useHistory();

  const HandleSubmit = (values: ResetPasswordForm) => {
    dispatch(
      resetPasswordThunkAction({
        data: {
          pass1: values.password,
          pass2: values.repeatPassword,
          token: (params as any).token,
        },
        onSuccess: (res) => {
          MySwal.fire(
            'Success',
            'Your password has been successfully reset',
            'success'
          ).then(() => {
            history.push(`${AUTH_ROUTES.root}${AUTH_ROUTES.signIn}`);
          });

          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire(
            'Error',
            'There was a problem, please try again',
            'warning'
          );
          return Promise.reject(err);
        },
      })
    );
  };

  return (
    <Container className="mt-5">
      <Row className="align-items-center">
        <Col>
          <Card
            style={{
              minHeight: '30vh',
              padding: '30px 10%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <Typography className="text-center" variant="h2">
              Reset Password
            </Typography>
            <p className="text-center">Please enter a new password here:</p>
            <Formik
              initialValues={{ password: '', repeatPassword: '' }}
              validationSchema={ResetPasswordFormSchema}
              onSubmit={HandleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                isValid,
              }) => {
                const canSubmit = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formPassword">
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="password"
                          component="div"
                          data-testid="password-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formRepeatPassword">
                      <Form.Control
                        type="password"
                        name="repeatPassword"
                        placeholder="Repeat Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatPassword}
                        isValid={
                          touched.repeatPassword && !errors.repeatPassword
                        }
                        isInvalid={!!errors.repeatPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="repeatPassword"
                          component="div"
                          data-testid="repeat-password-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Box display="flex" justifyContent="center" padding="10px">
                      <Button disabled={!canSubmit} type="submit">
                        Reset Password
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
