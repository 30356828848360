import {
  EventPayloadFromLegacy,
  setCommunicationTarget,
} from 'utils/legacyComunication.service';
import { useStyles } from 'pages/home/views/Home/Home.styles';
import React, { useCallback, useEffect, useRef } from 'react';
import { useAuth } from 'utils/hooks/useAuth.hook';
import { useAppDispatch } from 'store/store';
import {
  updateLegacyLastEvent,
  updateLegacyHasLoaded,
} from 'store/legacyApp/legacyApp.slice';
import { logOut } from 'store/auth/auth.slice';

const Home: React.FC = () => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const remoteSrc = process.env.REACT_APP_LEGACY_APP_URL;

  // const remoteSrc = 'http://care.local:3001/legacy';

  const classes = useStyles();
  const { auth } = useAuth();
  const dispatch = useAppDispatch();

  const handleEventFromLegacy = useCallback(
    (eventData: EventPayloadFromLegacy) => {
      switch (eventData.event) {
        case 'loaded': {
          dispatch(updateLegacyHasLoaded(true));
          break;
        }
        case 'logOut': {
          dispatch(logOut());
          break;
        }
      }
      updateLegacyLastEvent(eventData);
    },
    [dispatch]
  );

  useEffect(() => {
    const handler = (event: MessageEvent<string>) => {
      if (!event.data || event.origin !== remoteSrc) return;
      const parsedEvent = JSON.parse(event.data) as EventPayloadFromLegacy;
      if (parsedEvent) handleEventFromLegacy(parsedEvent);
    };

    window.addEventListener('message', handler);

    // clean up
    return () => {
      window.removeEventListener('message', handler);
      dispatch(updateLegacyHasLoaded(false));
    };
  }, [dispatch, handleEventFromLegacy]);

  useEffect(() => {
    if (iFrameRef.current) {
      setCommunicationTarget(iFrameRef.current);
    }
  }, [iFrameRef]);

  return (
    <div data-testid="home_route">
      <iframe
        ref={iFrameRef}
        src={`${remoteSrc}/carex.html?token=${auth?.token}`}
        allow="camera; microphone"
        title="main"
        className={classes.iframe}
      ></iframe>
    </div>
  );
};

export default Home;
