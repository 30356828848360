import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from 'components/atoms/warnButton/WarnButton.styles';
import React from 'react';

const WarnButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }>) => {
  const classes = useStyles();
  return <Button className={classes.root} {...props}></Button>;
};

export default WarnButton;
