import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UsersService from 'api/users.api';
import React, { useState, useEffect } from 'react';
import AuthService from '../../api/auth.api';
import { useAuth } from 'utils/hooks/useAuth.hook';
import { useDispatch } from 'react-redux';
import { setUserThunkAction } from 'store/auth/auth.slice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useDebounce from 'utils/hooks/useDebounce.hook';

const MySwal = withReactContent(Swal);

const UserMasquerade = () => {
  const [userListOpen, setUserListOpen] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const { user: oldUser } = useAuth();
  const dispatch = useDispatch();

  const debouncedSearchTerm = useDebounce(search, 300);

  const handleOnChangeAutoComplete = async (value: any) => {
    if (oldUser && value) {
      dispatch(
        setUserThunkAction({
          data: {
            oldUser,
            newUserId: value.id,
          },
          onError: (err) => {
            MySwal.fire({
              title: 'Login Error',
              text: '',
              icon: 'error',
            });

            return Promise.reject(err);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (userListOpen && users?.length) {
      setListLoading(true);
    } else {
      setListLoading(false);
    }
  }, [userListOpen, users]);

  useEffect(() => {
    (async () => {
      try {
        if (debouncedSearchTerm !== '') {
          const response = await UsersService.searchProviders(
            debouncedSearchTerm
          );
          setUsers((response as any).rows);
        }
      } catch (error) {
        setUsers([]);
      }
    })();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!userListOpen) {
      setUsers([]);
    }
  }, [userListOpen]);

  return (
    <Autocomplete
      data-testid="user-masquerade"
      style={{ width: 300 }}
      open={userListOpen}
      disableClearable
      onOpen={() => {
        setUserListOpen(true);
      }}
      onClose={() => {
        setUserListOpen(false);
      }}
      onChange={(event, value) => {
        handleOnChangeAutoComplete(value);
      }}
      getOptionSelected={(option: any, value: any) =>
        option.fullname === value.fullname
      }
      getOptionLabel={(option: any) => `${option.fullname} (${option.id})`}
      options={users}
      loading={listLoading}
      ListboxProps={{ 'data-testid': 'list-box' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Switch"
          //variant="outlined"
          onChange={(ev) => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== '') {
              setSearch(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            // @ts-ignore
            'data-testid': 'content-input',
            endAdornment: (
              <React.Fragment>
                {listLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default UserMasquerade;
