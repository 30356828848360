import { useStyles } from 'components/footer/Footer.styles';
import React from 'react';

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="footer-element">
      <span>
        Contact Support:
        <a href="mailto:support@careexpand.com">support@careexpand.com</a>|
      </span>{' '}
      <span>
        <a href="https://careexpand.com/termsandconditions.html">Terms and Conditions</a>|
      </span>{' '}
      <span>
        <a href="https://careexpand.com">Careexpand LLC. All rights reserved 2016 - 2019.</a>
      </span>
    </div>
  );
};

export default Footer;
