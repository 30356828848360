import { Box, Button, Card, Typography } from '@material-ui/core';
import WarnButton from 'components/atoms/warnButton/WarnButton';
import { ErrorMessage, Formik } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import ProviderAccessCard from 'pages/auth/components/ProviderAccessCard';
import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import { isForgotPasswordLoadingSelector } from 'store/auth/auth.selectors';
import {
  forgotPasswordThunkAction,
  requestOTPThunkAction,
  signInThunkAction,
} from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ResetPasswordSchema } from 'validations';
import { useAuth } from 'utils/hooks/useAuth.hook';
import ResendOtp from '../../components/ResendOtp';
import AuthService from 'api/auth.api';

interface ForgotPasswordForm {
  email: string;
}

const MySwal = withReactContent(Swal);

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isForgotPasswordLoadingSelector);
  const history = useHistory();
  const [action, setAction] = useState('');
  const { auth } = useAuth();

  if (auth) {
    return <Redirect to={HOME_ROUTES.root.path}></Redirect>;
  }

  const handleSignIn = (values: any) => {
    dispatch(
      signInThunkAction({
        data: { email: values.email, otp: values.otp, isOTPLogin: true },
        onError: (err) => {
          if (err.status === 409) {
            MySwal.fire({
              title: 'Login Error',
              text:
                "You have not verified your account yet. Please use your verification link to verify your account before you sign in. If you would like to have the verification email resent, please click 'Send Verification' below. If you think that this has occured in error, please contact your account manager.",
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#aaaaaa',
              confirmButtonText: 'Send Verification',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await AuthService.resendVerification(
                  `data={"email":"${values.email}"}`
                );
                MySwal.fire('Success', 'Verification email sent', 'success');
              }
            });
            return Promise.reject(err);
          } else {
            MySwal.fire('Login Error', 'Invalid login values', 'warning');
            return Promise.reject(err);
          }
        },
      })
    );
  };

  const HandleSubmit = (values: ForgotPasswordForm) => {
    if (action === 'link') {
      dispatch(
        forgotPasswordThunkAction({
          data: values,
          onSuccess: (res) => {
            MySwal.fire(
              'Success',
              'Check your email for link to reset',
              'success'
            ).then(() => {
              history.push(`${AUTH_ROUTES.root}${AUTH_ROUTES.signIn}`);
            });

            return Promise.resolve(res);
          },
          onError: (err) => {
            MySwal.fire({
              title: 'Login Error',
              text: 'Invalid login values.',
              icon: 'error',
            });

            return Promise.reject(err);
          },
        })
      );
    } else {
      dispatch(
        requestOTPThunkAction({
          data: values,
          onSuccess: (res) => {
            MySwal.fire({
              title: 'Please Enter OTP',
              html: <ResendOtp email={values.email} dispatch={dispatch} />,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#aaaaaa',
              confirmButtonText: 'Login',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'Enter OTP',
                required: 'true',
                pattern: '[0-9]{6}',
              },
              validationMessage: 'Enter valid OTP',
            }).then((result) => {
              if (result.isConfirmed) {
                handleSignIn({ email: values.email, otp: result.value });
              }
            });

            return Promise.resolve(res);
          },
          onError: (err) => {
            MySwal.fire({
              title: 'Login Error',
              text: 'Invalid login values.',
              icon: 'error',
            });

            return Promise.reject(err);
          },
        })
      );
    }
  };

  return (
    <Container className="mt-5">
      <Row className="align-items-center">
        <Col md={6}>
          <ProviderAccessCard></ProviderAccessCard>
        </Col>
        <Col>
          <Card>
            <Typography className="text-center" variant="h2">
              Forgot your password?
            </Typography>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={ResetPasswordSchema}
              onSubmit={HandleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                isValid,
              }) => {
                const canSubmit = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="email"
                          component="div"
                          data-testid="email-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Box display="flex" justifyContent="space-around">
                      <Button
                        disabled={!canSubmit}
                        type="submit"
                        onClick={() => setAction('link')}
                      >
                        Send Link
                      </Button>
                      <Button
                        disabled={!canSubmit}
                        type="submit"
                        onClick={() => setAction('otp')}
                      >
                        Request OTP
                      </Button>
                      <WarnButton
                        component={Link}
                        to={AUTH_ROUTES.root + AUTH_ROUTES.signIn}
                      >
                        Go Back
                      </WarnButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
