import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from 'api/auth.api';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { CreateAccountForm } from 'pages/auth/views/createAccount/CreateAccount';
import {
  AuthState,
  CEThunkPayload,
  CreateAccountPayload,
  ForgotPasswordPayload,
  RequestOTPPayload,
  ISignIn,
  UserTokenData,
  SetUserPayload,
  resetPasswordPayload,
} from 'store/auth/auth.types';

const initialState: AuthState = {
  isSignInLoading: false,
  isGetUserIdLoading: false,
  isForgotPasswordLoading: false,
  isCreateAccountLoading: false,
  isSetUserLoading: false,
  isRequestOTPLoading: false,
  isResetPasswordLoading: false,
  auth: undefined,
  user: undefined,
};

const payloadFactory = (
  payload: CEThunkPayload<any>,
  service: Promise<any>
) => {
  const success = payload.onSuccess
    ? payload.onSuccess
    : (res: AxiosResponse) => Promise.resolve(res);
  const error = payload.onError
    ? payload.onError
    : (err: AxiosResponse) => Promise.reject(err);
  return service.then(success).catch(error);
};

export const signInThunkAction = createAsyncThunk(
  'auth/SignIn',
  (payload: CEThunkPayload<ISignIn>) =>
    payloadFactory(payload, AuthService.signIn(payload.data))
);

export const getUserIdThunkAction = createAsyncThunk(
  'auth/getUserId',
  (payload: CEThunkPayload<undefined>) =>
    payloadFactory(payload, AuthService.getUserId())
);

export const forgotPasswordThunkAction = createAsyncThunk(
  'auth/forgotPassword',
  (payload: CEThunkPayload<ForgotPasswordPayload>) =>
    payloadFactory(payload, AuthService.forgotPassword(payload.data))
);

export const requestOTPThunkAction = createAsyncThunk(
  'auth/requestOTP',
  (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, AuthService.requestOTP(payload.data))
);

export const createAccountThunkAction = createAsyncThunk(
  'auth/createAccount',
  (payload: CEThunkPayload<CreateAccountForm>) => {
    const formToPayload: CreateAccountPayload = {
      ...payload.data,
      timezone: {
        offset: '' + moment().utcOffset() / 60.0,
        zone: moment.tz.guess(),
      },
    };
    return payloadFactory(payload, AuthService.createDoctor(formToPayload));
  }
);

export const setUserThunkAction = createAsyncThunk(
  'auth/setUser',
  (payload: CEThunkPayload<SetUserPayload>) => {
    return payloadFactory(payload, AuthService.setUser(payload.data));
  }
);

export const resetPasswordThunkAction = createAsyncThunk(
  'auth/resetPassword',
  (payload: CEThunkPayload<resetPasswordPayload>) => {
    return payloadFactory(payload, AuthService.resetPassword(payload.data));
  }
);

const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    logOut: (state) => {
      state.auth = undefined;
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInThunkAction.pending, (state) => {
      state.isSignInLoading = true;
    });
    builder.addCase(signInThunkAction.fulfilled, (state, { payload }) => {
      state.isSignInLoading = false;
      const parsedToken = jwtDecode<UserTokenData>(payload.token);
      state.auth = { token: payload.token, tokenData: parsedToken };
    });
    builder.addCase(signInThunkAction.rejected, (state) => {
      state.isSignInLoading = false;
    });
    builder.addCase(getUserIdThunkAction.pending, (state) => {
      state.isGetUserIdLoading = true;
    });
    builder.addCase(getUserIdThunkAction.fulfilled, (state, { payload }) => {
      state.user = payload[0];
      state.isGetUserIdLoading = false;
    });
    builder.addCase(getUserIdThunkAction.rejected, (state, action) => {
      state.auth = undefined;
      state.user = undefined;
      state.isGetUserIdLoading = false;
    });
    builder.addCase(forgotPasswordThunkAction.pending, (state) => {
      state.isForgotPasswordLoading = true;
    });
    builder.addCase(
      forgotPasswordThunkAction.fulfilled,
      (state, { payload }) => {
        state.isForgotPasswordLoading = false;
      }
    );
    builder.addCase(forgotPasswordThunkAction.rejected, (state, action) => {
      state.isForgotPasswordLoading = false;
    });
    builder.addCase(requestOTPThunkAction.pending, (state) => {
      state.isRequestOTPLoading = true;
    });
    builder.addCase(requestOTPThunkAction.fulfilled, (state, { payload }) => {
      state.isRequestOTPLoading = false;
    });
    builder.addCase(requestOTPThunkAction.rejected, (state, action) => {
      state.isRequestOTPLoading = false;
    });
    builder.addCase(createAccountThunkAction.pending, (state) => {
      state.isCreateAccountLoading = true;
    });
    builder.addCase(
      createAccountThunkAction.fulfilled,
      (state, { payload }) => {
        state.isCreateAccountLoading = false;
      }
    );
    builder.addCase(createAccountThunkAction.rejected, (state, action) => {
      state.isCreateAccountLoading = false;
    });
    builder.addCase(setUserThunkAction.pending, (state) => {
      state.isSetUserLoading = true;
    });
    builder.addCase(setUserThunkAction.fulfilled, (state, { payload }) => {
      const parsedToken = jwtDecode<UserTokenData>(payload.token);
      state.auth = { token: payload.token, tokenData: parsedToken };
      state.isSetUserLoading = false;
    });
    builder.addCase(setUserThunkAction.rejected, (state, action) => {
      state.isSetUserLoading = false;
    });

    builder.addCase(resetPasswordThunkAction.pending, (state) => {
      state.isResetPasswordLoading = true;
    });
    builder.addCase(
      resetPasswordThunkAction.fulfilled,
      (state, { payload }) => {
        state.isResetPasswordLoading = false;
      }
    );
    builder.addCase(resetPasswordThunkAction.rejected, (state, action) => {
      state.isResetPasswordLoading = false;
    });
  },
});

export const { logOut } = authUserSlice.actions;
export default authUserSlice.reducer;
