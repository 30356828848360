import { Button, Typography } from '@material-ui/core';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface INotFound {
  location: Location;
}

const NotFound = ({ location }: INotFound) => {
  const query = location.search ? location.search : '';
  const path = location.pathname ? location.pathname : '';
  const legacyUrl = `${process.env.REACT_APP_LEGACY_APP_URL}${path}${query}`;

  if (legacyUrl && location) {
    location.replace(legacyUrl);
    return null;
  }

  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="text-center">
              <Typography variant="h2">
                Sorry, the page you are looking for couldn't be found.
              </Typography>
              <Button component={Link} to={HOME_ROUTES.root.path}>
                Go Home
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFound;
