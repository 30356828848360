import { Menu, MenuItem, useTheme } from '@material-ui/core';
import { updateApiToken } from 'api/carexGateway';
import { useStyles } from 'components/header/Header.styles';
import UserMasquerade from 'components/userMasquerade';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { getUserIdThunkAction, logOut } from 'store/auth/auth.slice';
import { ROLES } from 'utils/constants/users.constants';
import { useAuth } from 'utils/hooks/useAuth.hook';
import { useHostConfigs } from 'utils/hooks/useHostConfigs';
import {
  CEMessage,
  EventsToLegacy,
  messageSend,
} from 'utils/legacyComunication.service';

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const currentTheme = useTheme();
  const hostConfig = useHostConfigs();
  const classes = useStyles(currentTheme);

  const [dropDownState, setDropDownState] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth, user: currentUser } = useAuth();

  useEffect(() => {
    if (auth?.token) {
      updateApiToken(auth.token);
      dispatch(getUserIdThunkAction({ data: undefined }));
    }
  }, [auth, dispatch]);

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropDownState(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropDownState(null);
  };

  const handleLogout = () => {
    dispatch(logOut());
    setDropDownState(null);
  };

  const handleClick = (
    page: string,
    type: EventsToLegacy = 'navigationDoctors'
  ) => (evt: any) => {
    const message: CEMessage<EventsToLegacy> = {
      eventType: type,
      content: page,
    };

    handleDropdownClose();
    messageSend(message);
  };

  const menu = [
    { name: 'Home', url: HOME_ROUTES.root.path + '?page=home', page: '0' },
    {
      name: 'Coordination',
      url: HOME_ROUTES.root.path + '?page=coordination',
      page: '1',
    },
    {
      name: 'Telemed',
      url: HOME_ROUTES.root.path + '?page=telemed',
      page: '2',
    },
  ];

  return (
    <React.Fragment>
      <div data-testid="header-element" className={classes.mainBackground}>
        <div className={classes.containerRow}>
          <React.Fragment>
            <div>
              <a className={classes.headerImage} href="/">
                {hostConfig.clientInfo.name}
              </a>
            </div>
            {currentUser && (
              <React.Fragment>
                <div className={classes.navMenu}>
                  <Router>
                    <ul>
                      {menu.map((item, i) => (
                        <li key={i}>
                          <Link to={item.url} onClick={handleClick(item.page)}>
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Router>
                </div>
                {(currentUser.role === ROLES.superAdmin ||
                  currentUser.wasSuperAdmin) && (
                  <div className={classes.adminSwitcher}>
                    <UserMasquerade></UserMasquerade>
                  </div>
                )}

                <div className={classes.userMenuToggler}>
                  <button
                    data-testid="user-menu"
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                  >
                    {currentUser.PName} {currentUser.PLastname}
                    <i className="fas fa-bars nav-icon"></i>
                  </button>
                  {dropDownState && (
                    <Router>
                      <Menu
                        open={Boolean(dropDownState)}
                        keepMounted
                        onClose={handleDropdownClose}
                        anchorEl={dropDownState}
                      >
                        <MenuItem
                          onClick={handleClick('', 'navigationProfile')}
                          component={Link}
                          to={HOME_ROUTES.root.path + '?page=profile'}
                        >
                          {t('Header:profile&settings')}
                        </MenuItem>

                        <MenuItem
                          onClick={handleClick('', 'navigationReferrals')}
                          component={Link}
                          to={HOME_ROUTES.root.path + '?page=referrals'}
                        >
                          {t('Header:referrals')}
                        </MenuItem>

                        <MenuItem
                          onClick={handleClick('3')}
                          component={Link}
                          to={HOME_ROUTES.root.path + '?page=group-manager'}
                        >
                          {t('Header:manage-group')}
                        </MenuItem>

                        <MenuItem onClick={handleLogout}>
                          {t('Header:log-out')}{' '}
                        </MenuItem>
                      </Menu>
                    </Router>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
