import PrivateRoute from 'components/privateRoute';
import AuthRouter, { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import HomeRouter, { HOME_ROUTES } from 'pages/home/HomeRouter';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from "pages/notFound/views/NotFound"

const RootRouter = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute
          path={HOME_ROUTES.root.path}
          exact
          component={HomeRouter}
        ></PrivateRoute>
        <Route path={AUTH_ROUTES.root} component={AuthRouter}></Route>
        <Route
          path="*"
          component={() => <NotFound location={window.location}/>}
        />
      </Switch>
    </Router>
  );
};

export default RootRouter;
