import { Box, Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { CurrentHostSettings } from 'utils/currentHost.utils';
import { useHostConfigs } from 'utils/hooks/useHostConfigs';

const ProviderAccessCard: React.FC = () => {
  const hostConfigs = useHostConfigs() as CurrentHostSettings;
  return (
    <Card raised={false}>
      <Typography variant="h2" className="text-center">
        <span>{hostConfigs.clientInfo.name}</span> Providers Access
      </Typography>
      <p className="text-center">
        Improving clinical outcomes <br />
        Building better patient engagement <br />
        Increasing financial performance
      </p>
      <p className="text-center">
        See, interact, and manage your patient beyond the physical encounter
      </p>
      <Box display="flex" justifyContent="space-around">
        <Button>I'm a Patient</Button>
        <Button>Our website</Button>
      </Box>
    </Card>
  );
};

export default ProviderAccessCard;
