import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { AxiosError } from 'axios';
import get from 'lodash/get.js';
import { currentHostName, currentHostSettings } from 'utils/currentHost.utils';

const sentryStatus = {
  init: false,
};
const codeVersion = process.env.CODE_VERSION;

const init = () => {
  if (currentHostName.includes('localhost')) return;
  if (currentHostSettings) {
    Sentry.init({
      dsn: currentHostSettings.clientInfo.sentryKey,
      environment: currentHostSettings.clientInfo.env,
      release: codeVersion,
      ignoreErrors: [
        'Network Error',
        'Network request failed',
        'Failed to fetch',
        'NetworkError',
        /401/,
        'Request aborted',
        'ResizeObserver loop limit exceeded',
      ],
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.05,
      beforeSend(event) {
        const status = Number(get(event, 'extra.response.status'));

        // If is a credentials problems while logging in then don't send an event
        if (status === 406) {
          return null;
        }

        return event;
      },
      beforeBreadcrumb(breadcrumb: any, hint: any) {
        const customBreadcrumb = breadcrumb;
        if (customBreadcrumb.category === 'ui.click') {
          const { target } = hint.event;

          if (target.ariaLabel) {
            customBreadcrumb.message = target.ariaLabel;
          }
        }
        return customBreadcrumb;
      },
    });
  }

  sentryStatus.init = true;
};

const KNOWN_HTTP_ERROR: Number[] = [];

const api = (error: AxiosError, extraInfo = {}) => {
  if (!sentryStatus.init) return;

  const url = get(error, ['config', 'url']);
  const method = get(error, ['config', 'method']);
  const status = Number(get(error, ['response', 'status']));
  const apiMessage =
    get(error, ['response', 'data', 'errorDesc']) ||
    get(error, ['response', 'statusText']) ||
    get(error, ['message']) ||
    get(error, ['name']);

  let uri = '';
  if (url) uri = url.replace(get(error, ['config', 'baseURL']) ?? '', '');

  const message = `[API] [${method}] ${status} ${uri} - ${apiMessage}`;

  let knownError = 'false';

  // Progress of bookmark
  if (KNOWN_HTTP_ERROR.includes(status)) {
    knownError = 'true';
  }

  const response = error.response || '';
  const request = error.config;

  Sentry.withScope((scope) => {
    if (status) {
      scope.setTag('statusCode', status);
    }

    scope.setTag('known-error', knownError);
    Sentry.captureEvent({
      message,
      logger: 'api',
      extra: {
        error,
        extraInfo,
        response,
        request,
      },
    });
  });
};

const KNOWN_UI_MESSAGES: any[] = [];

const ui = (error = {}, extraInfo = {}) => {
  if (!sentryStatus.init) return;

  const { message } = error as Error;

  let knownError = 'false';
  if (KNOWN_UI_MESSAGES.includes(message.trim())) {
    knownError = 'true';
  }

  Sentry.withScope((scope) => {
    scope.setTag('known-error', knownError);
    Sentry.captureEvent({
      message: `[UI] ${message}`,
      logger: 'ui',
      extra: {
        error,
        extraInfo,
      },
    });
  });
};

export default init;
export { api as apiReport, ui as uiReport };
