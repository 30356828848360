import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    fontSize: '10px',
    textAlign: 'center',
    maxWidth: '400px',
    margin: '32px auto 0',
  },
});
