import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventPayloadFromLegacy } from 'utils/legacyComunication.service';

interface LegacyAppState {
  hasLoaded: boolean;
  lastEvent?: EventPayloadFromLegacy;
}

const initialState: LegacyAppState = {
  hasLoaded: false,
  lastEvent: undefined,
};

const legacyAppSlice = createSlice({
  name: 'legacyApp',
  initialState,
  reducers: {
    updateLegacyLastEvent: (
      state,
      action: PayloadAction<EventPayloadFromLegacy>
    ) => {
      state.lastEvent = action.payload;
    },
    updateLegacyHasLoaded: (state, action: PayloadAction<boolean>) => {
      state.hasLoaded = action.payload;
    },
  },
});

export const {
  updateLegacyLastEvent,
  updateLegacyHasLoaded,
} = legacyAppSlice.actions;
export default legacyAppSlice.reducer;
