import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAuth } from 'utils/hooks/useAuth.hook';

interface PrivateRouteProps extends RouteProps {}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { auth } = useAuth();

  const location = useLocation();

  return auth?.token ? (
    <Route {...rest}></Route>
  ) : (
    <Redirect
      to={{
        pathname: AUTH_ROUTES.root + AUTH_ROUTES.signIn,
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
