import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import { currentHostSettings } from 'utils/currentHost.utils';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Raleway", sans-serif',
  },
  palette: {
    primary: {
      main: currentHostSettings.theme.colors.primary.base,
      light: currentHostSettings.theme.colors.primary[400],
    },
    secondary: {
      main: currentHostSettings.theme.colors.secondary.base,
    },
    warning: {
      main: currentHostSettings.theme.colors.alert,
    },
    background: {
      default: currentHostSettings.theme.colors.grey.base,
    },
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderRadius: '20px',
      },
      outlinedPrimary: {
        '&:hover, &:focus': {
          backgroundColor: currentHostSettings.theme.colors.primary.base,
          color: '#fff',
        },
      },
      outlinedSecondary: {
        '&:hover, &:focus': {
          backgroundColor: currentHostSettings.theme.colors.secondary.base,
          color: '#fff',
        },
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '22px',
        color: currentHostSettings.theme.colors.secondary.base,
        fontWeight: 700,
        marginBottom: '16px',
      },
    },
    MuiCard: {
      root: {
        borderRadius: '6px',
        padding: '16px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        'html, body, #root': {
          height: '100vh',
          overflow: 'hidden',
        },
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'outlined',
      disableElevation: true,
      disableRipple: true,
      color: 'secondary',
    },
    MuiCard: {
      raised: false,
      elevation: 0,
    },
  },
});

const AppWrappers: React.FC = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>{children}</Provider>
    </ThemeProvider>
  );
};

export default AppWrappers;
