import Layout from 'components/layout';
import React from 'react';
import RootRouter from 'rootRouter';

function App() {
  return (
    <React.Fragment>
      <Layout>
        <RootRouter></RootRouter>
      </Layout>
    </React.Fragment>
  );
}

export default App;
