interface CEHostInfo {
  name: string;
  clientId: string;
  logo: string;
  patientLogo: string;
  email: string;
  tawk: string;
  styleName: ThemeNames;
  env: CEEnvironments;
  sentryKey: string;
}

type CEEnvironments =
  | 'dev'
  | 'mcs-stage'
  | 'mcs-prod'
  | 'demo'
  | 'prod'
  | 'stage';
type CEClientsInfo = {
  [key in HOST_NAMES]: CEHostInfo;
};

export type ThemeNames = 'mcsTheme' | 'baseTheme';

export enum HOST_NAMES {
  stageApiPCPMCS = 'stage-api.pcp.careexpand.mcs.saas-innovasolutions.com',
  stagePCPMCS = 'stage.pcp.careexpand.mcs.saas-innovasolutions.com',
  stageUCMCS = 'stage.uc.careexpand.mcs.saas-innovasolutions.com',
  stageApiUcMCS = 'stage-api.uc.careexpand.mcs.saas-innovasolutions.com',
  prodUCMCS = 'uc.careexpand.mcs.saas-innovasolutions.com',
  prodPCPMCS = 'pcp.careexpand.mcs.saas-innovasolutions.com',
  localhost = 'localhost',
  careexpand = 'careexpand',
  devCareExpand = 'dev.careexpand.com',
  demoCareExpand = 'demo.careexpand.com',
  appCareExpand = 'app.careexpand.com',
}

const MCS_BASE_LOGO = './themes/mcs/logo.svg';
const CAREEXPAND_LOGO = '/assets/images/logo-careexpand.svg';
const GENERAL_SENTRY_DSN =
  'https://237d94aecdba492c86014ead85810081@o503395.ingest.sentry.io/5588469';

export const clientInfo: CEClientsInfo = {
  'stage-api.pcp.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: MCS_BASE_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-stage',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'stage.pcp.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: MCS_BASE_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-stage',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'stage.uc.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: './themes/mcs/MCS_Medilina_Logo.svg',
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-stage',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'stage-api.uc.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: './themes/mcs/MCS_Medilina_Logo.svg',
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-stage',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'uc.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: './themes/mcs/MCS_Medilina_Logo.svg',
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-prod',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'pcp.careexpand.mcs.saas-innovasolutions.com': {
    name: 'MCS',
    clientId: 'MCS',
    logo: MCS_BASE_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'mcsTheme',
    env: 'mcs-prod',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'app.careexpand.com': {
    name: 'CareExpand',
    clientId: 'CareExpand',
    logo: CAREEXPAND_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@carexpand.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'baseTheme',
    env: 'prod',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'demo.careexpand.com': {
    name: 'Demo CareExpand',
    clientId: 'CareExpand',
    logo: CAREEXPAND_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@carexpand.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'baseTheme',
    env: 'demo',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  'dev.careexpand.com': {
    name: 'Dev CareExpand',
    clientId: 'CareExpand',
    logo: CAREEXPAND_LOGO,
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@carexpand.com',
    tawk: 'https://embed.tawk.to/5fbf15dda1d54c18d8ed7c63/default',
    styleName: 'baseTheme',
    env: 'dev',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  localhost: {
    name: 'MCS',
    clientId: 'MCS',
    logo: './themes/mcs/MCS_Medilina_Logo.svg',
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/57e13122184cdc458d78b0b7/1e5vu6oe1',
    styleName: 'mcsTheme',
    env: 'dev',
    sentryKey: GENERAL_SENTRY_DSN,
  },
  careexpand: {
    name: 'MCS',
    clientId: 'MCS',
    logo: './themes/mcs/MCS_Medilina_Logo.svg',
    patientLogo: './themes/mcs/MCS_Master_logo.svg',
    email: 'support@mcs.com',
    tawk: 'https://embed.tawk.to/57e13122184cdc458d78b0b7/1e5vu6oe1',
    styleName: 'baseTheme',
    env: 'dev',
    sentryKey: GENERAL_SENTRY_DSN,
  },
};
