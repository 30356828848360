import { Box, Button, Card, Typography } from '@material-ui/core';
import WarnButton from 'components/atoms/warnButton/WarnButton';
import { ErrorMessage, Formik } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import ProviderAccessCard from 'pages/auth/components/ProviderAccessCard';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { isSignInLoadingSelector } from 'store/auth/auth.selectors';
import { signInThunkAction } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import { useAuth } from 'utils/hooks/useAuth.hook';
import { SignInSchema } from 'validations';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthService from 'api/auth.api';

const MySwal = withReactContent(Swal);

const initialValues = {
  email: '',
  password: '',
};

const SignIn: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isSignInLoadingSelector);

  const { auth } = useAuth();

  if (auth) {
    return <Redirect to={HOME_ROUTES.root.path}></Redirect>;
  }

  const handleSignIn = (values: typeof initialValues) => {
    dispatch(
      signInThunkAction({
        data: { email: values.email, pass: values.password },
        onError: (err) => {
          if (err.status === 409) {
            MySwal.fire({
              title: 'Login Error',
              text:
                "You have not verified your account yet. Please use your verification link to verify your account before you sign in. If you would like to have the verification email resent, please click 'Send Verification' below. If you think that this has occured in error, please contact your account manager.",
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#aaaaaa',
              confirmButtonText: 'Send Verification',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await AuthService.resendVerification(
                  `data={"email":"${values.email}"}`
                );
                MySwal.fire('Success', 'Verification email sent', 'success');
              }
            });
            return Promise.reject(err);
          } else {
            MySwal.fire('Login Error', 'Invalid login values', 'warning');
            return Promise.reject(err);
          }
        },
      })
    );
  };

  return (
    <Container className="mt-5">
      <Row className="align-items-center">
        <Col md={6}>
          <ProviderAccessCard></ProviderAccessCard>
        </Col>
        <Col>
          <Card>
            <Typography variant="h2" className="text-center">
              Sign In
            </Typography>
            <p className="text-center">
              New to MCS?
              <Link to={AUTH_ROUTES.root + AUTH_ROUTES.createAccount}>
                {' '}
                Create your account
              </Link>
            </p>
            <p className="text-center">
              See, interact, and manage your patient beyond the physical
              encounter
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={SignInSchema}
              onSubmit={handleSignIn}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                touched,
                errors,
              }) => {
                const canSignIn = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="email"
                          component="div"
                          data-testid="email-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="password"
                          component="div"
                          data-testid="password-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Box display="flex" justifyContent="space-around">
                      <Button disabled={!canSignIn} type="submit">
                        Sign In
                      </Button>
                      <Button
                        component={Link}
                        to={AUTH_ROUTES.root + AUTH_ROUTES.resetPassword}
                      >
                        Reset Password
                      </Button>
                      <WarnButton
                        component={Link}
                        to={AUTH_ROUTES.root + AUTH_ROUTES.resetPassword}
                      >
                        Go Back
                      </WarnButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
