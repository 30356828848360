import Home from 'pages/home/views/Home/Home';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const HOME_ROUTES = {
  root: {
    path: '/',
    params: '?page=home',
  },
};

const HomeRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={Home}></Route>
      <Route path={`${match.path}${HOME_ROUTES.root.path}`} component={Home}></Route>
    </Switch>
  );
};

export default HomeRouter;
