import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppWrappers from 'components/appWrappers/AppWrappers';
import { requestOTPThunkAction } from 'store/auth/auth.slice';

interface IResendOtp {
  email: string;
  dispatch: any;
}

const ResendOtp = ({ email, dispatch }: IResendOtp) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(120);
  const [minutes, setMinutes] = useState('02');
  const [seconds, setSeconds] = useState('00');

  useEffect(() => {
    const m = Math.floor(timeLeft / 60);
    const s = timeLeft % 60;
    setMinutes(m < 10 ? `0${m}` : `${m}`);
    setSeconds(s < 10 ? `0${s}` : `${s}`);
  }, [timeLeft]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <AppWrappers>
      <Typography>
        Please enter the OTP that is sent by Email / Phone
      </Typography>
      <Box display="flex" justifyContent="space-around" px={4} mt={2}>
        <Typography>{`Time left: ${minutes}:${seconds}`}</Typography>
        {timeLeft === 0 ? (
          <Typography>
            &#x21bb;
            <Button
              size="small"
              variant="text"
              onClick={() => {
                dispatch(
                  requestOTPThunkAction({
                    data: { email },
                  })
                );
                setTimeLeft(120);
              }}
            >
              Resend OTP
            </Button>
          </Typography>
        ) : null}
      </Box>
    </AppWrappers>
  );
};

export default ResendOtp;
