import { AxiosRequestConfig } from 'axios';

type ValueOf<T> = T[keyof T];
// BEWARE
// Due to https://github.com/Microsoft/TypeScript/issues/13195 and https://stackoverflow.com/questions/54489817/typescript-partialt-type-without-undefined
// AvailableParams has string | undefined
type AvailableParams = Partial<Record<ValueOf<typeof QUERY_PARAMS>, string>>;

export type ServiceNames = keyof ReturnType<typeof apiBaseEndpoints>;

export interface QueryParamsResolver extends AxiosRequestConfig {
  query?: string;
  params?: AvailableParams;
}

export const resolveUrl = (
  serviceName: ServiceNames,
  { query = '', params = {} }: QueryParamsResolver
): string => {
  const serviceMapping = apiBaseEndpoints();
  const path = serviceMapping[serviceName];

  const url = Object.keys(params).reduce((acc, param) => {
    return acc.replace(param, (params as any)[param]);
  }, path);

  return query ? `${url}?${query}` : url;
};

export enum QUERY_PARAMS {
  id = ':id',
}

export const apiBaseEndpoints = () => {
  const signIn = '/signIn';
  const getUserId = '/getUserId';
  const createDoctor = '/createDoctor';
  const forgotPassword = '/forgotPassword';
  const requestOTP = '/requestOTP';
  const searchProviders = '/searchProvidersByNPIOrName';
  const setUser = '/setUser';
  const resetPassword = '/resetPassword';
  const resendVerification = '/resendVerification';

  return {
    signIn,
    getUserId,
    createDoctor,
    forgotPassword,
    requestOTP,
    searchProviders,
    setUser,
    resetPassword,
    resendVerification,
  };
};
